.input-file{
    margin-right: 10px;
}

.upload-status-container{
    margin-top: 5px;
    margin-right: 5px;
  }

  .upload-img-container{
    position: relative;
  }
  
  .upload-img-container > .uploaded-img{
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
  .uploaded-file-status{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  }
  
  .tick-icon-i{
    width: 25px;
    height: 25px;
    position:absolute;
    margin-top: -5px;
    margin-top: -5px;
  }
  
   .tick-icon{
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }

  .error-msg{
    margin-top: 8px;
    background: #FEEFEF;
    border-radius: 8px;
    padding: 6px 16px;
    display: flex;
  }
  
  .error-msg img{
    margin-right: 4px;
  }
  
  .error-msg span{
    font-size: 10px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #DA1414;
  }
  






@keyframes ldio-z1gxsuzd9od {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.ldio-z1gxsuzd9od div {
  position: absolute;
  width: 16px;
  height: 40px;
  top: 30px;
  animation: ldio-z1gxsuzd9od 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.ldio-z1gxsuzd9od div:nth-child(1) {
  transform: translate(12px, 0);
  background: #157759;
  animation-delay: -0.6s;
}

.ldio-z1gxsuzd9od div:nth-child(2) {
  transform: translate(32px, 0);
  background: #53ab8b;
  animation-delay: -0.4s;
}

.ldio-z1gxsuzd9od div:nth-child(3) {
  transform: translate(52px, 0);
  background: #82dbb8;
  animation-delay: -0.2s;
}

.ldio-z1gxsuzd9od div:nth-child(4) {
  transform: translate(72px, 0);
  background: #a2fdd9;
  animation-delay: -1s;
}

.loadingio-spinner-bars-ckokqeh99t {
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-z1gxsuzd9od {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.8);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-z1gxsuzd9od div {
  box-sizing: content-box;
}